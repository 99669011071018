
<script>

import { getExercise } from "@/services/exercise.service";
import { useAuth0 } from "@auth0/auth0-vue";
  export default {
    components: {
    },
    data() {
      return {
        series: [{
            name: "Orders",
            type: "area",
          },
        ],
        chartOptions: {
          chart: {
            height: 370,
            type: "line",
            toolbar: {
              show: false,
            },
          },
          stroke: {
            curve: "straight",
            dashArray: [0, 0, 8],
            width: [2, 0, 2.2],
          },
          fill: {
            opacity: [0.1, 0.9, 1],
          },
          markers: {
            size: [0, 0, 0],
            strokeWidth: 2,
            hover: {
              size: 4,
            },
          },
          xaxis: {
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          grid: {
            show: true,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
            padding: {
              top: 0,
              right: -2,
              bottom: 15,
              left: 10,
            },
          },
          legend: {
            show: true,
            horizontalAlign: "center",
            offsetX: 0,
            offsetY: -5,
            markers: {
              width: 9,
              height: 9,
              radius: 6,
            },
            itemMargin: {
              horizontal: 10,
              vertical: 0,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "30%",
              barHeight: "70%",
            },
          },
          tooltip: {
            shared: true,
            y: [{
                formatter: function (y) {
                  if (typeof y !== "undefined") {
                    return y.toFixed(0);
                  }
                  return y;
                },
              },
            ],
          },
        },
      };
    },
    created: function () {
        this.getData();
    },
    methods: {
        async getData() {
            try {
                const { getAccessTokenSilently } = useAuth0();
                const accessToken = await getAccessTokenSilently();
                const { data, error } = await getExercise(this.$route.params.id, accessToken);
                if (data) {
                  var xaxisarray = [];
                  var xaxisarrayscores = [];
                  var date;
                  for(var i=data.yourExerciseHistory.length -1;i >=0;i--){ //Looping backwards
                  date = new Date(data.yourExerciseHistory[i].date);
                  xaxisarray.push(date.getDate() + '/' + date.getMonth());
                  xaxisarrayscores.push(data.yourExerciseHistory[i].score);
                  }
                }
                if (error) {
                    this.error = [];
                }

            } catch (e) {
                console.log('failure' + e)
            }

        // Format data correctly
        /*let formattedData = this.follower.map((e, i) => {
            return {
                x: e.date,
                y: e.media,
            };
        });*/

        // update the series with axios data
        this.series = [
            {
            name: "Score",
            data: xaxisarrayscores,
            },
        ];
        this.$refs.history.updateOptions({
            xaxis: {
                categories: xaxisarray //ie ["a","b","c","d"]
            }
        })
        },
    },
  };
</script>

<template>
  <div class="card">
    <div class="card-header border-0 align-items-center d-flex">
      <h4 class="card-title mb-0 flex-grow-1">Your latest exercise history</h4>
    </div>
    <!-- end card header -->

    <div class="card-body p-0 pb-2">
      <div class="w-100">
        <apexchart class="apex-charts" ref="history" height="380" type="line" dir="ltr" :series="series" :options="chartOptions">
        </apexchart>
        <!-- <div
          id="customer_impression_charts"
          data-colors='["--vz-primary", "--vz-success", "--vz-danger"]'
          class="apex-charts"
          dir="ltr"
        ></div> -->
      </div>
    </div>
    <!-- end card body -->
  </div>
  <!-- end card -->
</template>