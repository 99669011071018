<script>
import { getExercise } from "@/services/exercise.service";
import { useAuth0 } from "@auth0/auth0-vue";
/**
 * Pie chart component
 */
 function getChartColorsArray(colors){
  colors = JSON.parse(colors);
  return colors.map(function (value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
          if (color) {
          color = color.replace(" ", "");
          return color;
          }
          else return newValue;
      } else {
          var val = value.split(',');
          if (val.length == 2) {
              var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
              rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
              return rgbaColor;
          } else {
              return newValue;
          }
      }
  });
}
export default {
  
  components: {
  },
  data() {
    return {
      series: [0, 0, 0],
      chartOptions: {
          chart: {
          height: 300,
          type: "donut",
          },
          labels: ["Strength", "Mobility", "Cardio"],
          legend: {
          position: "bottom",
          },
          dataLabels: {
          dropShadow: {
              enabled: false,
          },
          },
          colors: getChartColorsArray('["--vz-primary", "--vz-success", "--vz-danger"]'),
      },
    };
  },
  created: function () {
        this.getCompositionData();
    },
    methods: {
        async getCompositionData() {
            try {
                const { getAccessTokenSilently } = useAuth0();
                const accessToken = await getAccessTokenSilently();
                const { data, error } = await getExercise(this.$route.params.id, accessToken);
                var composition = [];
                if (data) {
                  composition.push(data.strengthscore);
                  composition.push(data.mobilityscore);
                  composition.push(data.cardioscore);
                  // update the series with axios data
                  this.series = composition;
                }
                if (error) {
                    this.error = [];
                }
            } catch (e) {
                console.log('failure' + e)
            }

        },
    },
};
</script>

<template>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title mb-0">Exercise composition</h4>
      </div>
      <div class="card-body">
        <apexchart
          class="apex-charts"
          height="395"
          dir="ltr"
          :series="series"
          :options="chartOptions"
        ></apexchart>
      </div>
    </div>
    <!-- end card -->
  </template>
